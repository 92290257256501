import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

const LanguageSwitcher = () => {
  const {i18n} = useTranslation();

  const setEnglish = useCallback(() => {
    i18n.changeLanguage('en');
  }, [i18n]);

  const setJapanease = useCallback(() => {
    i18n.changeLanguage('ja');
  }, [i18n]);

  return (
    <div>
      <button
        onClick={setEnglish}
      >
        English
      </button>
      <button
        onClick={setJapanease}
      >
        Japanease
      </button>
    </div>
  );
};

export default LanguageSwitcher;
